import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'start'
  },
  {
    path: 'start',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'internetbanken',
    loadChildren: () => import('./neos/neos.module').then(m => m.NeosModule)
  },
  {
    path: '**',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
