import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HttpClientModule} from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {ENVIRONMENT, LOCAL_STORAGE} from './application/injection-tokens'
import {environment} from '../environments/environment'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    // Include this here as it used implicitly by others
    BankIdModule
  ],
  providers: [
    {provide: LOCAL_STORAGE, useValue: localStorage},
    {provide: ENVIRONMENT, useValue: environment}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
